<template>
    <div class="container" id="EnrollAuditDetail">
        <el-page-header @back="$router.back()" :content="'报名详情'"></el-page-header>
        <div class="EnrollAuditDetail_content">
            <template v-if="false">
            <el-descriptions title="报名统计"></el-descriptions>
            <div class="statistics">
                <p>累计已报名共有 44 条记录，合计 15 支队伍 159 个人。其中 31 条记录审核通过，合计 16 支队伍 98  名运动员。</p>
                <el-button type="primary" size="small">刷新统计</el-button>
            </div>
            <el-row>
                <el-table
                    v-loading="statistics.loading"
                    element-loading-text="数据刷新中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :data="statistics.list"
                    border
                    size="mini"
                    style="width: 100%"
                    show-summary
                    sum-text="合计"
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        align="center"
                        prop="GroupName"
                        label="组别"
                        width="150">
                    </el-table-column>
                    <el-table-column align="center" width="150">
                        <div slot="header">
                            <p>累计已报名</p>
                            <p>（其中今日新增）</p>
                        </div>
                        <template>
                            <el-button type="text">
                                8支队伍（0）
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="已修改未提交" align="center">
                        <el-table-column label="上次审核通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="上次审核不通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="已提交待审核" align="center">
                        <el-table-column label="上次审核通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="上次审核不通过" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="新提交" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="审核通过" align="center">
                        <el-table-column label="未被举报" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="有运动员被举报" align="center" min-width="150">
                            <template>
                                <el-button type="text">
                                    8支队伍（0）
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="审核不通过" align="center" min-width="150">
                        <template>
                            <el-button type="text">
                                8支队伍（0）
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            </template>
            <el-descriptions title="筛选条件"></el-descriptions>
            <el-form :model="form" label-width="100px" size="small" class="form">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="代表机构：">
                            <el-input v-model.trim="form.companyName" placeholder="机构全称或简称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="代表队：">
                            <el-input v-model.trim="form.teamName" placeholder="代表队名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="运动员：">
                            <el-input v-model.trim="form.memberName" placeholder="运动员姓名"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="组别：">
                            <el-select v-model="form.groupId" placeholder="请选择">
                                <el-option label="不限" :value="0"></el-option>
                                <el-option v-for="(item,index) in groups" :key="index"
                                    :label="item.GroupName" 
                                    :value="item.GroupId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="审核状态：">
                            <el-select v-model="form.auditStatus" placeholder="请选择">
                                <el-option v-for="(item,index) in auditStatusList" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="上次审核状态：" label-width="140px">
                            <el-select v-model="form.prevAuditStatus" placeholder="请选择">
                                <el-option v-for="(item,index) in prevAuditStatusList" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="运动员被举报：" label-width="170px">
                            <el-select v-model="form.isInform" placeholder="请选择">
                                <el-option v-for="(item,index) in informs" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="排序：">
                            <el-select v-model="form.sort" placeholder="请选择">
                                <el-option v-for="(item,index) in sorts" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-button 
                            type="primary" size="small" 
                            style="margin-left:50px;width:100px"
                            @click="query()"
                        >查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="statistics" v-if="isGet">
                <p v-if="AllCount == 0">没有满足条件的记录</p>
                <p v-else>共有 {{AllCount}} 条记录满足条件，{{(list.length == AllCount) ? '已全部加载' : `已加载前 ${list.length} 条记录`}}。<!--<el-button type="text" style="padding:8px 0">将所有记录设置为审核不通过</el-button>--></p>
            </div>
            <el-row>
                <el-table
                    v-loading="loading"
                    element-loading-text="数据刷新中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :data="list"
                    border
                    size="mini"
                    style="width: 100%"
                    max-height="500px"
                >
                    <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                    <el-table-column label="比赛项目" width="100" prop="EventsName"></el-table-column>
                    <el-table-column label="组别" prop="LeagueGroupName"></el-table-column>
                    <el-table-column label="代表队" prop="DeputationName"></el-table-column>
                    <el-table-column label="参赛者" prop="TeamName"></el-table-column>
                    <el-table-column label="代表机构" prop="CompanyName" min-width="100"></el-table-column>
                    <el-table-column label="报名时间" min-width="100">
                        <template slot-scope="scope">
                            <span>{{scope.row.EnrollTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="提交时间" min-width="100">
                        <template slot-scope="scope">
                            <span>{{scope.row.SubmitTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核时间" min-width="100">
                        <template slot-scope="scope">
                            <span>{{scope.row.AuditTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态" width="100">
                        <template slot-scope="scope">
                            <span>{{['未提交','待审核','审核通过','审核不通过'][scope.row.AuditStatus]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="上次审核" width="80">
                        <template slot-scope="scope" v-if="!([2,3].includes(scope.row.AuditStatus))">
                            <span v-if="scope.row.LastAuditStatus == 2">通过</span>
                            <span v-if="scope.row.LastAuditStatus == 3">不通过</span>
                            <span v-if="scope.row.LastAuditStatus == null">无</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="运动员被举报" width="80">
                        <template slot-scope="scope">
                            <template v-if="[1,3].includes(scope.row.GroupType)">
                                <span>{{['','否','是','部分'][scope.row.ImpeachState - 1]}}</span>
                            </template>
                            <template v-else>
                                <span>{{['','无','全部','部分'][scope.row.ImpeachState - 1]}}</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <el-button 
                            size="mini"
                            type="primary"
                            slot-scope="scope"
                            @click="openDetail(scope.$index, scope.row.TeamId, scope.row.GroupType)"
                        >详情</el-button>
                    </el-table-column>
                    <div slot="append" class="load_more" v-show="list.length < AllCount" @click="getList()">加载更多</div>
                </el-table>
            </el-row>
        </div>
        <el-dialog :title="detailDialog.isGetData ? (['个人运动员详情','队伍详情','组合详情'][detailDialog.info.TeamType - 1]) : ''" :visible.sync="detailDialog.show" :close-on-click-modal="false" width="75%" style="min-width:1000px;">
            <template v-if="detailDialog.isGetData">
                <div class="content">
                    <div class="enroll_detail">
                        <div class="info">
                            <el-descriptions :column="1" :labelStyle="labelStyle">
                                <el-descriptions-item label="比赛项目">{{detailDialog.info.EventsName}}</el-descriptions-item>
                                <el-descriptions-item label="组别">{{detailDialog.info.LeagueGroupName}}</el-descriptions-item>
                                <el-descriptions-item label="代表队" v-if="detailDialog.info.DeputationName">{{detailDialog.info.DeputationName}}</el-descriptions-item>
                                <el-descriptions-item label="代表机构">{{detailDialog.info.CompanyName || '无'}}</el-descriptions-item>
                                <el-descriptions-item label="报名时间">{{detailDialog.info.EnrollTime | dateFormat('yyyy-MM-dd HH:mm')}}</el-descriptions-item>
                            </el-descriptions>
                            <p style="font-size: 16px;color: #000;margin-top: 10px;" v-if="detailDialog.info.DeputationName">代表队在该组别报名了 {{detailDialog.ThisGroupDeputationEnrollNum}} {{['名运动员','','对组合'][detailDialog.info.TeamType - 1]}}</p>
                        </div>
                        <div class="operator" v-if="detailDialog.operateInfo">
                            <el-descriptions title="报名操作员" :column="1" :labelStyle="{...labelStyle,'width':'75px'}">
                                <el-descriptions-item label="姓名">{{detailDialog.operateInfo.Name}}</el-descriptions-item>
                                <el-descriptions-item label="手机号">{{detailDialog.operateInfo.Phone}}</el-descriptions-item>
                                <el-descriptions-item label="肖像照片" >
                                    <img :src="detailDialog.operateInfo.PortraitPhoto" v-if="detailDialog.operateInfo.PortraitPhoto">
                                    <span v-else>无</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div class="contact" v-if="detailDialog.info.DeputationName">
                            <!-- <el-descriptions title="代表队联系人" :column="1" :labelStyle="{...labelStyle,'width':'75px'}">
                                <el-descriptions-item label="姓名">{{detailDialog.contactInfo.Name}}</el-descriptions-item>
                                <el-descriptions-item label="手机号">{{detailDialog.contactInfo.Phone}}</el-descriptions-item>
                                <el-descriptions-item label="肖像照片">
                                    <img :src="detailDialog.contactInfo.PortraitPhoto" v-if="detailDialog.contactInfo.PortraitPhoto">
                                    <span v-else>无</span>
                                </el-descriptions-item>
                            </el-descriptions> -->
                            <el-descriptions title="代表队其他人员"></el-descriptions>
                            <el-table 
                                :data="detailDialog.DeputationOtherUsers"
                                border
                                size="mini"
                                style="width: 100%"
                                max-height="280px"
                            >
                                <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                                <el-table-column label="身份" width="60">
                                    <template slot-scope="scope">
                                        <template v-for="(item,index) in scope.row.Roles">
                                            <span :key="index">{{item.RoleName}}</span>
                                            <template v-if="index < scope.row.Roles.length - 1">，</template>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column label="姓名">
                                    <template slot-scope="scope">
                                        <span class="lookImg" @click="lookImg(scope.row.PortraitPhoto)">{{scope.row.Name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="性别" width="50" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.Gender == 1 ? '男' : '女'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="手机号" width="100" prop="Phone"></el-table-column>
                                <el-table-column label="身份证件">
                                    <template slot-scope="scope">
                                        <span class="lookImg" @click="lookImg(scope.row.IdentityPhoto1, scope.row.IdentityPhoto2)">{{scope.row.IdentityNo}}{{[1,3].includes(scope.row.IdentityType) ? '' : '（' + cards[scope.row.IdentityType] + '）'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="年龄" prop="Age" width="50" align="center"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <template v-if="detailDialog.info.TeamType == 1">
                        <el-divider></el-divider>
                        <div class="member_info" :style="detailDialog.memberInfo.PortraitPhoto ? '' : 'max-width:500px'">
                            <div class="content" >
                                <div class="img" v-if="detailDialog.memberInfo.PortraitPhoto" style="margin-right:15px">
                                    <img :src="detailDialog.memberInfo.PortraitPhoto" alt="">
                                </div>
                                <el-descriptions :column="1" :labelStyle="labelStyle" :style="detailDialog.memberInfo.PortraitPhoto ? '' : 'width:100%'">
                                    <el-descriptions-item label="姓名">
                                        <span>{{detailDialog.memberInfo.Name}}</span>
                                        <el-button 
                                            type="danger" 
                                            size="mini" 
                                            style="margin-left:15px;padding:5px 10px"
                                            @click="report(1, detailDialog.memberInfo.UserId)"
                                            v-if="type != 1 && !detailDialog.memberInfo.ImpeachTime && detailDialog.memberInfo.IsAthlete"
                                        >举报运动员</el-button>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="性别">{{detailDialog.memberInfo.Gender == 1 ? '男' : '女'}}</el-descriptions-item>
                                    <el-descriptions-item label="身份">
                                        <template v-for="(item,index) in detailDialog.memberInfo.Roles">
                                            <span :key="index">{{item.RoleName}}</span>
                                            <template v-if="index < detailDialog.memberInfo.Roles.length - 1">，</template>
                                        </template>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="手机号码">{{detailDialog.memberInfo.Phone}}</el-descriptions-item>
                                    <el-descriptions-item label="证件类型">{{cards[detailDialog.memberInfo.IdentityType]}}</el-descriptions-item>
                                    <el-descriptions-item label="证件号码">
                                        <span>{{detailDialog.memberInfo.IdentityNo}}</span>
                                        <span class="lookImg" style="margin-left:15px" @click="lookImg(detailDialog.memberInfo.IdentityPhoto1, detailDialog.memberInfo.IdentityPhoto2)">查看证件照片</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="年龄">{{detailDialog.memberInfo.Age}}</el-descriptions-item>
                                    <el-descriptions-item label="球衣号码"  v-if="field.jerseyNumber != 0">{{detailDialog.memberInfo.JerseyNumber}}</el-descriptions-item>
                                </el-descriptions>
                            </div>
                            <div class="report" v-if="detailDialog.memberInfo.ImpeachTime && detailDialog.memberInfo.IsAthlete">
                                <p>该运动员于{{detailDialog.memberInfo.ImpeachTime | dateFormat("yyyy-MM-dd")}}被举报：{{detailDialog.memberInfo.ImpeachReason}}</p>
                                <div v-if="type != 1">
                                    <el-button type="info" size="mini" @click="report(2, detailDialog.memberInfo.UserId, detailDialog.memberInfo.ImpeachReason, detailDialog.memberInfo.ImpeachTime)">修改</el-button>
                                    <el-button type="danger" size="mini" @click="cancelReport(detailDialog.memberInfo.UserId)">撤销</el-button>
                                </div>
                                <div v-else></div>
                            </div>
                        </div>
                    </template>
                    <div class="team_info" v-else-if="detailDialog.info.TeamType == 2">
                        <el-descriptions :title="detailDialog.info.DeputationName + '成员'"></el-descriptions>
                        <el-table
                            :data="detailDialog.members"
                            border
                            size="mini"
                            style="width: 100%"
                            max-height="300px"
                            :row-class-name="itemClass"
                        >
                            <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                            <el-table-column label="身份" width="100">
                                <template slot-scope="scope">
                                    <template v-for="(item,index) in scope.row.Roles">
                                        <span :key="index">{{item.RoleName}}</span>
                                        <template v-if="index < scope.row.Roles.length - 1">，</template>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column label="姓名">
                                <template slot-scope="scope">
                                    <span class="lookImg" @click="lookImg(scope.row.PortraitPhoto)">{{scope.row.Name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="性别" width="50" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.Gender == 1 ? '男' : '女'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="手机号" prop="Phone"></el-table-column>
                            <el-table-column label="身份证件">
                                <template slot-scope="scope">
                                    <span class="lookImg" @click="lookImg(scope.row.IdentityPhoto1, scope.row.IdentityPhoto2)">{{scope.row.IdentityNo}}{{[1,3].includes(scope.row.IdentityType) ? '' : '（' + cards[scope.row.IdentityType] + '）'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="年龄" prop="Age" width="50" align="center"></el-table-column>
                            <el-table-column label="球衣号码" prop="JerseyNumber" width="50" align="center" v-if="field.jerseyNumber != 0"></el-table-column>
                            <el-table-column label="操作" v-if="type != 1">
                                <template slot-scope="scope" v-if="scope.row.IsAthlete">
                                    <template v-if="scope.row.ImpeachTime">
                                        <el-button type="info" size="mini" @click="report(2, scope.row.UserId, scope.row.ImpeachReason, scope.row.ImpeachTime)">修改</el-button>
                                        <el-button type="primary" size="mini" @click="cancelReport(scope.row.UserId)">撤销</el-button>
                                    </template>
                                    <template v-else>
                                        <el-button type="danger" size="mini" @click="report(1, scope.row.UserId)">举报</el-button>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <template v-else-if="detailDialog.info.TeamType == 3">
                        <el-divider></el-divider>
                        <div class="doubles_info">
                            <table>
                                <tbody>
                                    <tr>
                                        <td :rowspan="field.jerseyNumber != 0 ? 8 : 7">
                                            <div class="img" v-if="detailDialog.doubles[0].PortraitPhoto">
                                                <img :src="detailDialog.doubles[0].PortraitPhoto">
                                            </div>
                                        </td>
                                        <td class="left">
                                            <el-button 
                                                type="danger" 
                                                size="mini" 
                                                style="margin-right:15px;padding:5px 10px"
                                                @click="report(1, detailDialog.doubles[0].UserId)"
                                                v-if="type != 1 && !detailDialog.doubles[0].ImpeachTime && detailDialog.doubles[0].IsAthlete"
                                            >举报运动员</el-button>
                                            <span>{{detailDialog.doubles[0].Name}}</span>
                                        </td>
                                        <td class="center">姓名</td>
                                        <td class="right">
                                            <span>{{detailDialog.doubles[1].Name}}</span>
                                            <el-button 
                                                type="danger" 
                                                size="mini" 
                                                style="margin-left:15px;padding:5px 10px"
                                                @click="report(1, detailDialog.doubles[1].UserId)"
                                                v-if="type != 1 && !detailDialog.doubles[1].ImpeachTime && detailDialog.doubles[1].IsAthlete"
                                            >举报运动员</el-button>
                                        </td>
                                        <td :rowspan="field.jerseyNumber != 0 ? 8 : 7">
                                            <div class="img" v-if="detailDialog.doubles[1].PortraitPhoto">
                                                <img :src="detailDialog.doubles[1].PortraitPhoto">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="left">{{detailDialog.doubles[0].Gender == 1 ? '男' : '女'}}</td>
                                        <td class="center">性别</td>
                                        <td class="right">{{detailDialog.doubles[1].Gender == 1 ? '男' : '女'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">
                                            <template v-for="(item,index) in detailDialog.doubles[0].Roles">
                                                <span :key="index">{{item.RoleName}}</span>
                                                <template v-if="index < detailDialog.doubles[0].Roles.length - 1">，</template>
                                            </template>
                                        </td>
                                        <td class="center">角色</td>
                                        <td class="right">
                                            <template v-for="(item,index) in detailDialog.doubles[1].Roles">
                                                <span :key="index">{{item.RoleName}}</span>
                                                <template v-if="index < detailDialog.doubles[1].Roles.length - 1">，</template>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="left">{{detailDialog.doubles[0].Phone}}</td>
                                        <td class="center">手机号码</td>
                                        <td class="right">{{detailDialog.doubles[1].Phone}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">{{cards[detailDialog.doubles[0].IdentityType]}}</td>
                                        <td class="center">证件类型</td>
                                        <td class="right">{{cards[detailDialog.doubles[1].IdentityType]}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">
                                            <span class="lookImg" style="margin-right:15px" @click="lookImg(detailDialog.doubles[0].IdentityPhoto1, detailDialog.doubles[0].IdentityPhoto2)">查看证件照片</span>
                                            <span>{{detailDialog.doubles[0].IdentityNo}}</span>
                                        </td>
                                        <td class="center">证件号码</td>
                                        <td class="right">
                                            <span>{{detailDialog.doubles[1].IdentityNo}}</span>
                                            <span class="lookImg" style="margin-left:15px" @click="lookImg(detailDialog.doubles[1].IdentityPhoto1, detailDialog.doubles[1].IdentityPhoto2)">查看证件照片</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="left">{{detailDialog.doubles[0].Age}}</td>
                                        <td class="center">年龄</td>
                                        <td class="right">{{detailDialog.doubles[1].Age}}</td>
                                    </tr>
                                    <tr v-if="field.jerseyNumber != 0">
                                        <td class="left">{{detailDialog.doubles[0].JerseyNumber}}</td>
                                        <td class="center">球衣号码</td>
                                        <td class="right">{{detailDialog.doubles[1].JerseyNumber}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="report" v-if="detailDialog.doubles[0].ImpeachTime && detailDialog.doubles[0].IsAthlete">
                                                <p>该运动员于{{detailDialog.doubles[0].ImpeachTime | dateFormat("yyyy-MM-dd")}}被举报：{{detailDialog.doubles[0].ImpeachReason}}</p>
                                                <div v-if="type != 1">
                                                    <el-button type="info" size="mini" @click="report(2, detailDialog.doubles[0].UserId, detailDialog.doubles[0].ImpeachReason, detailDialog.doubles[0].ImpeachTime)">修改</el-button>
                                                    <el-button type="danger" size="mini" @click="cancelReport(detailDialog.doubles[0].UserId)">撤销</el-button>
                                                </div>
                                                <div v-else></div>
                                            </div>
                                        </td>
                                        <td class="center"></td>
                                        <td colspan="2">
                                            <div class="report" v-if="detailDialog.doubles[1].ImpeachTime && detailDialog.doubles[1].IsAthlete">
                                                <p>该运动员于{{detailDialog.doubles[1].ImpeachTime | dateFormat("yyyy-MM-dd")}}被举报：{{detailDialog.doubles[1].ImpeachReason}}</p>
                                                <div v-if="type != 1">
                                                    <el-button type="info" size="mini" @click="report(2, detailDialog.doubles[1].UserId, detailDialog.doubles[1].ImpeachReason, detailDialog.doubles[1].ImpeachTime)">修改</el-button>
                                                    <el-button type="danger" size="mini" @click="cancelReport(detailDialog.doubles[1].UserId)">撤销</el-button>
                                                </div>
                                                <div v-else></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
                <div class="status">
                    <div>
                        <div>
                            <span class="label">审核状态 :</span>
                            <span :style="['','','color:#67C23A','color:#F56C6C'][detailDialog.info.AuditStatus]">{{['未提交','待审核','审核通过','审核不通过'][detailDialog.info.AuditStatus]}}</span>
                        </div>
                        <div v-if="[2,3].includes(detailDialog.info.AuditStatus)">
                            <span class="label" style="">审核时间 :</span>
                            <span>{{detailDialog.info.AuditTime | dateFormat("yyyy-MM-dd HH:mm")}}</span>
                        </div>
                        <div v-if="[1,2,3].includes(detailDialog.info.AuditStatus)">
                            <span class="label">提交审核时间 :</span>
                            <span>{{detailDialog.info.SubmitTime | dateFormat("yyyy-MM-dd HH:mm")}}</span>
                        </div>
                    </div>
                </div>
                <div class="status" v-if="detailDialog.info.LastAuditStatus && !([2,3].includes(detailDialog.info.AuditStatus))">
                    <div>
                        <div>
                            <span class="label">上次审核状态 :</span>
                            <span :style="['','','color:#67C23A','color:#F56C6C'][detailDialog.info.LastAuditStatus]">{{['','','审核通过','审核不通过'][detailDialog.info.LastAuditStatus]}}</span>
                        </div>
                        <div>
                            <span class="label">上次审核时间 :</span>
                            <span>{{detailDialog.info.AuditTime | dateFormat("yyyy-MM-dd HH:mm")}}</span>
                        </div>
                    </div>
                </div>
                <div class="status" v-if="detailDialog.info.AuditRejectReason">
                    <div>
                        <div style="width:100%;max-width: none;">
                            <span class="label">审核不通过原因 :</span>
                            <el-input type="textarea" resize="none" :rows="3" :value="detailDialog.info.AuditRejectReason" style="width: 100%;max-width:400px;"></el-input>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer">
                    <template v-if="type != 1">
                        <div v-if="[1].includes(detailDialog.info.AuditStatus)">
                            <el-button type="success" @click="audit()">审核通过</el-button>
                            <el-button type="danger" @click="noAudit()">审核不通过</el-button>
                        </div>
                        <div v-else-if="[3].includes(detailDialog.info.AuditStatus)">
                            <el-button type="success" @click="audit()">改为审核通过</el-button>
                        </div>
                        <div v-else-if="[2].includes(detailDialog.info.AuditStatus)">
                            <el-button type="danger" @click="noAudit()">改为审核不通过</el-button>
                        </div>
                        <div v-else></div>
                    </template>
                    <template v-else>
                        <div></div>
                    </template>
                    <div>
                        <el-button @click="detailDialog.show = false">关&ensp;闭</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>        
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import {
    GetLeagueEventsGroup, //获取分组
    GetLeagueEnrollDataAuditListList, //获取赛事报名数据
    GetLeagueEnrollTeamDetail, //获取队伍/个人报名详情
    ApproveLeagueEnrollTeam, //报名参赛审核通过
    RejectLeagueEnrollTeam, //报名参赛审核不通过
    GetOneLeagueEnrollDataAuditInfo,//获取单条记录数据
    ImpeachEnrollTeamMember,//举报参赛运动员
    CancelImpeachEnrollTeamMember,//撤销举报参赛运动员
} from '@/api'
import {
    GetLeagueEnrollFieldsOptions
} from '@/api/enroll';
import {dateFormat} from '@/assets/js';
export default {
    data(){
        var subLeagueId = parseInt(this.$route.params.subLeagueId),
            type = parseInt(this.$route.params.type);
        return {
            subLeagueId: isNaN(subLeagueId) ? 0 : subLeagueId,
            type: isNaN(type) ? 0 : type,//0统计审核 1查看不可修改
            labelStyle: {'width':'80px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'},
            statistics:{
                loading:false,
                list:[{GroupName:"1111111"},{GroupName:"1111111"},{GroupName:"1111111"},{GroupName:"1111111"}]
            },
            form:{
                companyName: "",
                teamName: "",
                memberName: "",
                groupId: 0,
                auditStatus: 4,
                prevAuditStatus: 0,
                isInform: 0,
                sort: 1
            },
            groups:[],
            auditStatusList:[
                {text: "不限", id: null},
                {text: "已提交", id: 4},
                {text: "未提交", id: 0},
                {text: "待审核", id: 1},
                {text: "审核通过", id: 2},
                {text: "审核不通过", id: 3}
            ],
            prevAuditStatusList:[
                {text: "不限", id: 0},
                {text: "通过", id: 2},
                {text: "不通过", id: 3},
                {text: "无", id: 4},
                {text: "通过或无", id: 5}
            ],
            informs:[
                {text: "不限", id: 0},
                {text: "有/是", id: 1},
                {text: "无/否", id: 2},
                {text: "全部/是", id: 3},
                {text: "可参赛", id: 4},
            ],
            sorts:[
                {text: "按报名时间，从早到晚", id: 1},
                {text: "按报名时间，从晚到早", id: 2},
                {text: "按提交时间，从早到晚", id: 3},
                {text: "按提交时间，从晚到早", id: 4},
                {text: "按审核时间，从早到晚", id: 5},
                {text: "按审核时间，从晚到早", id: 6},
            ],
            cards:{
                1:'身份证（港澳台居住证）',
                2:'护照',
                4:'台湾居民来往大陆通行证',
                5:'港澳居民来往内地通行证'
            },
            loading:false,
            isGet:false,
            lastId:0,
            list:[],
            AllCount:0,
            detailDialog:{
                show: false,
                teamId: 0,
                index: 0,
                isGetData: false,
                info:{},
                operateInfo:{},
                contactInfo:{},
                memberInfo:{},
                members:[],
                doubles:[],
                ThisGroupDeputationEnrollNum: 0,
                DeputationOtherUsers: []
            },
            field:{
                jerseyNumber: 0,
                portraitImg: 0
            },
            imagePreviews:[]
        }
    },
    created(){
        GetLeagueEventsGroup({
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                this.groups = data.GroupList;
            }
        })
        GetLeagueEnrollFieldsOptions({
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                let {
                    JerseyNumberOption = 0,
                    PortraitPhotoOption = 0
                } = data;
                this.field = {
                    jerseyNumber: JerseyNumberOption,
                    portraitImg: PortraitPhotoOption
                }
            }
        })
        this.getList();
    },
    methods:{
        query(info){
            if(info){
                let form = this.form;
            }
            this.lastId = 0;
            this.getList();
        },
        getList(){
            var form = this.form;
            this.loading = true;
            var lastId = this.lastId;
            if(lastId == 0){
                this.list = [];
            }
            GetLeagueEnrollDataAuditListList({
                SubLeagueId: this.subLeagueId,
                CompanyName: form.companyName,
                deputatioanName: form.teamName,
                AthleteName: form.memberName,
                LeagueGroupId: form.groupId,
                AuditStatus: form.auditStatus,
                LastAuditStatus: form.prevAuditStatus,
                ImpeachState: form.isInform,
                TimeOrderType: (form.sort % 2 == 1 ? (form.sort + 1) : form.sort) / 2,
                TimeOrderIsAsc: form.sort % 2 == 1,
                continuation: {
                    LastId: lastId,
                    MaxCount: 20
                }
            }).then(data=>{
                this.loading = false;
                if(data.Code == 0){
                    if(!this.isGet){
                        this.isGet = true;
                    }
                    this.AllCount = data.AllCount;
                    var ContinueList = data.ContinueList || [];
                    if(ContinueList.length == 0){
                    }else{
                        this.lastId = ContinueList[ContinueList.length - 1].TeamId;
                        this.$set(this,'list',[...this.list,...ContinueList]);
                    }
                }
            }).catch(()=>{
                this.loading = false;
            })
        },
        getSummaries(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
            });
            return sums;
        },
        itemClass({row, rowIndex}){
            var className = 'team-' + rowIndex,
                reportClass = "report-" + rowIndex;
            if(row.ImpeachTime){
                this.$nextTick(function(){
                    var trDom = document.querySelector('.' + className),
                        newDom = document.createElement('tr');
                    var parent = trDom.parentNode,
                        len = trDom.childNodes.length;
                    newDom.className = reportClass;
                    newDom.innerHTML = `
                        <td colspan="${len - 1}" class="el-table__cell">
                            <div class="cell" style="color:#F56C6C">该运动员于${dateFormat(row.ImpeachTime,'yyyy-MM-dd')}被举报：${row.ImpeachReason}</div>
                        </td>
                    `
                    trDom.firstChild.setAttribute('rowspan',2);
                    let next = trDom.nextSibling;
                    if (parent.lastChild == trDom) {
                        parent.appendChild(newDom);
                    } else {
                        parent.insertBefore(newDom, next)
                    }
                    if(next.className == reportClass){
                        parent.removeChild(next)
                    }
                });
            }
            return className;
        },
        openDetail(index, teamId, teamType){
            var detail = this.detailDialog;
            detail.show = true;
            detail.index = index;
            detail.isGetData = false;
            detail.teamId = teamId;
            const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            GetLeagueEnrollTeamDetail({
                SubLeagueId: this.subLeagueId,
                TeamId: teamId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    detail.info = data.BaseInfo || {};
                    detail.operateInfo = data.DeputationOperateUser;
                    detail.contactInfo = data.DeputationLinkUser;
                    detail.DeputationOtherUsers = data.DeputationOtherUsers || [];
                    detail.ThisGroupDeputationEnrollNum = data.ThisGroupDeputationEnrollNum || 0;
                    let members = data.Members || [];
                    if(teamType == 1){
                        detail.memberInfo = members[0] || {};
                    }else if(teamType == 2){
                        detail.members = members;
                    }else if(teamType == 3){
                        detail.doubles = members;
                    }
                    detail.isGetData = true;
                }else if(data.Code == 3893){
                    let list = this.list;
                    list.splice(index, 1);
                    this.$set(this,'list',list);
                    detail.show = false;
                    this.$message.error(`该${['个人运动员','队伍','组合'][teamType - 1]}记录已被删除！`);
                }else if(data.Code == 3894){
                    this.openDetail(index, teamId);
                    this.queryOneData(teamId);
                    this.$message.error(`该${['个人运动员','队伍','组合'][teamType - 1]}记录已被修改！`);
                }
            }).catch(()=>{
                loading.close();
            })
        },
        lookImg(){
            var imgs = [...arguments];
            if(imgs.length == 0){
                this.$message.warning('此队员未上传照片');
            }else{
                let imgs2 = [];
                for(var i=0;i<imgs.length;i++){
                    if(imgs[i]){
                        imgs2.push(imgs[i]);
                    }
                }
                if(imgs2.length == 0){
                    this.$message.warning('此队员未上传照片');
                }else{
                    this.imagePreviews = imgs2;
                    this.$refs['imagePreview'].showViewer = true;
                }
            }
        },
        report(type, userId, reason, time){
            var detail = this.detailDialog;
            this.$prompt(type == 2 ? `<div style="display:flex;justify-content: space-between;">
                    <span>举报原因：</span>
                    <span>举报时间：${dateFormat(time, "yyyy-MM-dd HH:mm")}</span>
                </div>` : '举报原因：', type == 2 ? '修改举报原因' : '举报运动员', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: type == 2 ? '保存修改' : '举报',
                cancelButtonText: '取消',
                inputType:'textarea',
                inputValue: reason,
                inputValidator:(value)=>{
                    if(!value){
                        return '请输入举报原因'
                    }
                }
            }).then(({ value }) => {
                ImpeachEnrollTeamMember({
                    "TeamId": detail.info.TeamId,
                    "AthlelteUserId": userId,
                    "ImpeachReason": value
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('操作成功');
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                    }else if(data.Code == 3893){
                        let list = this.list;
                        list.splice(detail.index, 1);
                        this.$set(this,'list',list);
                        detail.show = false;
                        this.$message.error(`该运动员记录已被删除！`);
                    }else if(data.Code == 3894){
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                        this.$message.error(`该运动员记录已被修改！`);
                    }
                })
            }).catch(() => {      
            });
        },
        cancelReport(userId){
            var detail = this.detailDialog;
            CancelImpeachEnrollTeamMember({
                "TeamId": detail.info.TeamId,
                "AthlelteUserId": userId
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('撤销成功');
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                }else if(data.Code == 3893){
                    let list = this.list;
                    list.splice(detail.index, 1);
                    this.$set(this,'list',list);
                    detail.show = false;
                    this.$message.error(`该运动员记录已被删除！`);
                }else if(data.Code == 3894){
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                    this.$message.error(`该运动员记录已被修改！`);
                }
            })
        },
        audit(){
            var detail = this.detailDialog;
            ApproveLeagueEnrollTeam({
                "SubLeagueId": this.subLeagueId,
                "TeamId": detail.info.TeamId,
                "SubmitTime": detail.info.SubmitTime
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success('操作成功');
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                }else if(data.Code == 3893){
                    let list = this.list;
                    list.splice(detail.index, 1);
                    this.$set(this,'list',list);
                    detail.show = false;
                    this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被删除！`);
                }else if(data.Code == 3894){
                    this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                    this.queryOneData(detail.info.TeamId);
                    this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被修改！`);
                }
            })
        },
        noAudit(){
            var detail = this.detailDialog;
            this.$prompt('审核不通过的原因：', '审核不通过', {
                confirmButtonText: '审核不通过',
                cancelButtonText: '取消',
                inputType:'textarea',
                inputValidator:(value)=>{
                    if(!value){
                        return '请输入审核不通过的原因'
                    }
                }
            }).then(({ value }) => {
                RejectLeagueEnrollTeam({
                    "SubLeagueId": this.subLeagueId,
                    "TeamId": detail.info.TeamId,
                    "SubmitTime": detail.info.SubmitTime,
                    "RejectReason": value
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('操作成功');
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                    }else if(data.Code == 3893){
                        let list = this.list;
                        list.splice(detail.index, 1);
                        this.$set(this,'list',list);
                        detail.show = false;
                        this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被删除！`);
                    }else if(data.Code == 3894){
                        this.openDetail(detail.index, detail.info.TeamId, detail.info.TeamType);
                        this.queryOneData(detail.info.TeamId);
                        this.$message.error(`该${['个人运动员','队伍','组合'][detail.info.teamType - 1]}记录已被修改！`);
                    }
                })
            }).catch(() => {      
            });
        },
        queryOneData(TeamId){
            GetOneLeagueEnrollDataAuditInfo({
                "TeamId": TeamId
            }).then(data=>{
                if(data.Code == 0){
                    this.$set(this.list,this.detailDialog.index,data.AuditInfo);
                }
            })
        }
    }
}
</script>
<style scoped>
.EnrollAuditDetail_content{
    padding-right: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
}
    .cell .el-button--text{
        padding: 0;
    }
    .form .el-row{
        margin-bottom: 0 !important;
    }
    .load_more{
        font-size: 16px;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
    }
    .load_more:hover{
        color: #409EFF;
    }
    .enroll_detail{
        display: flex;
    }
    .enroll_detail >div{
        flex: 1;
    }
    .enroll_detail > .info{
        max-width: 280px;
        padding-right: 10px;
    }
    .enroll_detail > .operator{
        max-width: 280px;
    }
    .enroll_detail > .contact{
        max-width: calc(100% - 560px);
    }
    .enroll_detail > .operator img,
    .enroll_detail > .contact img{
        width: 100px;
    }
    .team_info{
        margin-top: 20px;
    }
    .member_info{
        width: 100%;
        max-width: 650px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .member_info .content{
        display: flex;
    }
    .member_info .img,
    .member_info .img img{
        width: 150px;
    }
    .member_info .el-descriptions{
        width: calc(100% - 150px);
    }
    .contact .lookImg,
    .team_info .lookImg,
    .member_info .lookImg,
    .doubles_info .lookImg{
        color: #409EFF;
        cursor: pointer;
    }
    .member_info .report,
    .doubles_info .report{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #F56C6C;
    }
    .member_info .report p{
        width: calc(100% - 150px);
        padding-right: 10px;
    }
    .member_info .report button,
    .doubles_info .report button{
        margin: 0 10px 5px 0;
        
    }
    .doubles_info{
        display: flex;
        justify-content: center;
    }
    .doubles_info .img,
    .doubles_info .img img{
        width: 150px;
    }
    .doubles_info .center{
        width: 6em;
        text-align: center;
    }
    .doubles_info .left,
    .doubles_info .right{
        display: flex;
    }
    .doubles_info .left,
    .doubles_info .right,
    .doubles_info .center{
        padding-bottom: 12px;
        line-height: 1.4em;
    }
    .doubles_info .left{
        justify-content: right;
        padding-left: 10px;
    }
    .doubles_info .right{
        justify-content: left;
        padding-right: 10px;
    }
    .doubles_info  .report p{
        padding-right:10px;
    }
    .doubles_info  .report>div{
        text-align: right;
    }
    .el-divider{
        margin: 20px 0;
    }
    .status > div{
        display: flex;
        text-align: left;
        padding: 5px 0;
        color: #606266;
    }
    .status > div > div{
        width: 33%;
        max-width: 300px;
        display: flex;
    }
    .status > div > div .label{
        width: 120px;
        text-align: right;
        display: inline-block;
        margin-right: 10px;
    }
    .status > div > div:first-of-type{
        max-width: 240px;
    }
    .status > div > div:first-of-type .label{
        width: auto;
        text-align: left;
    }
    .dialog-footer{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .el-dialog__footer .status + .dialog-footer{
        margin-top: 15px;
    }
    .image_preview{
        display: none;
    }
    #EnrollAuditDetail .el-descriptions :v-deep(.el-descriptions__header){
        margin-bottom: 10px;
    }
</style>